/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {getScrollTriggers, setScrollTrigger} from "utils/scrolltriggers";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components), {ResourcePanel, Pin, InfoNugget, CareInstructions, Headline, CareItem, Obl} = _components;
  if (!CareInstructions) _missingMdxReference("CareInstructions", true);
  if (!CareItem) _missingMdxReference("CareItem", true);
  if (!Headline) _missingMdxReference("Headline", true);
  if (!InfoNugget) _missingMdxReference("InfoNugget", true);
  if (!Obl) _missingMdxReference("Obl", true);
  if (!Pin) _missingMdxReference("Pin", true);
  if (!ResourcePanel) _missingMdxReference("ResourcePanel", true);
  return React.createElement(React.Fragment, null, React.createElement(ResourcePanel, {
    id: "figure01",
    image: "figure01",
    imageAlt: "lorem ipsum",
    roundTopCorners: true,
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers
  }, React.createElement(Pin, {
    id: "figure01",
    type: "start",
    height: 200,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Vitiligo"), " is a condition in which pale patches form on the skin."), React.createElement(_components.p, null, "These patches are the result of a loss of pigment, or melanin.")), React.createElement(InfoNugget, null, React.createElement(_components.p, null, "Vitiligo is not contagious or painful; however, it may cause psychological distress, especially in individuals with darker skin, where it is more noticeable.")), React.createElement(Pin, {
    id: "figure01",
    type: "end",
    height: 200,
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(ResourcePanel, {
    id: "figure02",
    image: "figure02",
    imageAlt: "lorem ipsum",
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers,
    slug: props.data.mdx.frontmatter.slug
  }, React.createElement(Pin, {
    id: "figure02",
    type: "start",
    height: 200,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(_components.p, null, "Though the root causes of vitiligo are not fully understood, ", React.createElement(_components.strong, null, "non segmental vitiligo, the most common type, is thought to be autoimmune"), "."), React.createElement(_components.p, null, "The body attacks pigment producing cells called melanocytes.")), React.createElement(Pin, {
    id: "figure02",
    type: "end",
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(ResourcePanel, {
    id: "figure03",
    image: "figure03",
    imageAlt: "lorem ipsum",
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers
  }, React.createElement(Pin, {
    id: "figure03",
    type: "start",
    height: 400,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(_components.p, null, "Normally, melanocytes produce melanin, which is then transferred to the surrounding cells."), React.createElement(_components.p, null, "Without melanocytes, the skin cannot produce melanin, and the skin turns pale.")), React.createElement(Pin, {
    id: "figure03",
    type: "end",
    height: 400,
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(CareInstructions, null, React.createElement(Headline, {
    level: 3
  }, "Treatment"), React.createElement(_components.p, null, "There are several treatment options for vitiligo but they are not necessary. Though vitiligo is chronic and relapsing, it may be possible to reduce its appearance."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, "Over the counter ", React.createElement(Obl, {
    to: "https://amzn.to/3Orjxas",
    title: "Amazon Affiliate Link"
  }, "camouflage creams"), " can help even out the skintone."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, "Topical steroid creams may also be prescribed."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, "It is important to ", React.createElement(_components.a, {
    href: "/sun-damage"
  }, "protect your skin from the sun"), ", especially the patches which no longer contain melanin."), "\n"), "\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
